document.addEventListener("DOMContentLoaded", function () {
  function setupChart(list) {
    const existingClone = document.querySelector(
      ".comparison-chart__table-clone"
    );
    if (existingClone instanceof HTMLElement) {
      existingClone.remove();
    }
    if (list.matches) {
      // If media query matches
      const table = document.querySelector(
        ".js-moments-comparison-chart__table"
      );

      const tableClone = table.cloneNode(true);
      tableClone.classList.add("comparison-chart__table-clone");
      var fixCellBackground = Array.from(
        tableClone.querySelectorAll(
          "table.comparison-chart__table-clone tr.comparison-chart__mobile-row td.comparison-chart__fixed-side"
        )
      );

      fixCellBackground.forEach((cell) => {
        cell.classList.add("comparison-chart__cell__set-opaque");
      });
      document
        .querySelector(".moments-comparison-chart__table-scroll")
        .append(tableClone);
    }
  }

  var matchListener = window.matchMedia("(max-width: 992px)");
  setupChart(matchListener); // Call listener function at run time
  matchListener.addListener(setupChart); // Attach listener function on state changes
});
